import { IAppDataContent } from '@/interfaces/appdata';
import { ICategoryWithProducts } from '@/interfaces/products';
import { ITermsAndPrivacyLocale } from '@/interfaces/staticlocale';
import React from 'react';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import './termsAndPrivacy.style.scss';
import { AgreementDocument } from '@/interfaces/termsAndPrivacy';
import DocComponent from './DocComponent';

const Home = ({ pageContext }: TermsOfUseProps) => {
  const { templateData } = pageContext.appData;

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: pageContext.contentLocale.title,
  };

  return (
    <DefaultLayout {...defaultLayoutData}>
      <div className="default-screen-size terms_and_privacy">
        <div className="terms_and_privacy_content">
          <h1 className="tpy-6 terms-title">
            {pageContext.contentLocale.title}
          </h1>
          {pageContext.pageContent.content.components.map(
            (docComponent, idx) => (
              <DocComponent key={idx} component={docComponent} />
            )
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

interface TermsOfUseProps {
  pageContext: {
    appData: IAppDataContent;
    lang: string;
    categoriesWithProducts: ICategoryWithProducts[];
    currentPath: string;
    pathLang: string;
    pageContent: AgreementDocument;
    contentLocale: ITermsAndPrivacyLocale;
    widgetTarget: string;
  };
}

export default Home;
