import React from "react"
import {
  AgreementComponent,
  AgreementElement,
} from '@/interfaces/termsAndPrivacy';

interface DocComponentProps {
  component: AgreementComponent;
}

export const DocComponent = ({ component }: DocComponentProps) => {
  if (component.namedStyleType == 'TITLE') {
    return null;
  }

  const children = component.elements.map((docElement, idx) => (
    <DocElement key={idx} element={docElement} />
  ));

  let tagName = 'p';
  const props = {};

  if (component.namedStyleType == 'HEADING_1') {
    tagName = 'h2';
  }

  if (component.listId) {
    children.unshift(<span>- </span>);
  }

  return React.createElement(tagName, props, children);
};

interface DocElementProps {
  element: AgreementElement;
}

const DocElement = ({ element }: DocElementProps) => {
  let tagName = element.bold ? 'strong' : 'span';
  const props: { [key: string]: string } = {};

  if (element.underline) {
    props['className'] = '';
  }

  if (element.linkUrl) {
    tagName = 'a';
    props['href'] = element.linkUrl;
    props['target'] = '_blank';
  }

  return React.createElement(tagName, props, element.text);
};

export default DocComponent;
